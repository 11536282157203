import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#1F1F1F',
    },
    secondary: {
      main: '#18C980',
    },
  },
});
